import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@/components/Loader/Loader';
import { http } from '@/api/apiConfig';

const ApiLoader = () => {
  const dispatch = useDispatch();

  http.interceptors.response.use(
    (response) => {
      dispatch({ type: 'HIDE_LOADER' });
      return response;
    },
    (error) => {
      dispatch({ type: 'HIDE_LOADER' });
      return Promise.reject(error);
    }
  );

  http.interceptors.request.use(
    (config) => {
      dispatch({ type: 'SHOW_LOADER' });
      return config;
    },
    (error) => {
      dispatch({ type: 'HIDE_LOADER' });
      return Promise.reject(error);
    }
  );
  const isLoading = useSelector((state) => state.loader);

  return isLoading ? <Loader type="page" /> : null;
};

export default ApiLoader;
