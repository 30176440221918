class CSS {
  static merge(baseClass, modifiers) {
    if (baseClass === null) return '';
    if (modifiers === null || typeof modifiers !== 'object') return baseClass;

    let result = baseClass;

    modifiers.forEach((modifier) => {
      if (!modifier) return;
      result += ` ${baseClass}--${modifier}`;
    });

    return result;
  }
}

export default CSS;
