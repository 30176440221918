import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SearchIcon from 'images/search.svg';
import paths from '@/configs/paths';
import logo from 'images/logo-small.png';
import './Header.scss';
import CSS from '@/utils/css';

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="header">
      <NavLink to={paths.main} className="header__logo">
        <img src={logo} alt="" className="header__logo-image" />
        <span className="header__logo-title">{t('header.title')}</span>
      </NavLink>
      <div className="header__navigation">
        <NavLink to={paths.federation} className="header__navlink" activeClassName="header__navlink--active">
          {t('header.federation')}
        </NavLink>
        <NavLink to={paths.news} className="header__navlink" activeClassName="header__navlink--active">
          {t('header.news')}
        </NavLink>
        <NavLink to={paths.calendar} className="header__navlink" activeClassName="header__navlink--active">
          {t('header.calendar')}
        </NavLink>
        <NavLink to="/persons" className="header__navlink" activeClassName="header__navlink--active">
          {t('header.persons')}
        </NavLink>
        <NavLink to={paths.media} className="header__navlink" activeClassName="header__navlink--active">
          {t('header.media')}
        </NavLink>
        <NavLink to={paths.documents} className="header__navlink" activeClassName="header__navlink--active">
          {t('header.documents')}
        </NavLink>
      </div>
      <NavLink
        to={paths.search}
        className={CSS.merge('header__navlink', ['search'])}
        activeClassName="header__navlink--active"
      >
        <img src={SearchIcon} alt={t('header.search')} className="header__search" />
      </NavLink>
    </div>
  );
};

export default Header;
