const INITIAL_STATE = false;

export default function loaderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_LOADER': {
      return true;
    }
    case 'HIDE_LOADER': {
      return false;
    }
    default:
      return state;
  }
}
