import React from 'react';
import PropTypes from 'prop-types';

import './Loader.scss';

const Loader = ({ type }) => {
  switch (type) {
    case 'button': {
      return <div className="lds-dual-ring" />;
    }
    case 'page': {
      return (
        <div className="page-loader-wrapper">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

Loader.propTypes = {
  type: PropTypes.oneOf(['button', 'page']).isRequired,
};

export default Loader;
