/* eslint-disable no-console */
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import moment from 'moment';

import Router from '@/components/Router';
import Loader from '@/components/Loader';
import store from '@/store';
import '@/configs/locale/i18n';
import './index.scss';

import 'react-image-lightbox/style.css';
import { BrowserRouter } from 'react-router-dom';
import ApiLoader from '@/components/Loader/ApiLoader';

moment.locale('ru');

const App = () => {
  useEffect(() => {
    console.group('Федерация спортивной аэробики и фитнес-аэробики Томской области');
    console.log(`Версия ${__version}`);
    console.log(`AlderaSoft LLC. 2021`);
    console.groupEnd();
  }, []);

  return (
    <AppContainer key={Math.random()}>
      <Provider store={store}>
        <Suspense fallback={<Loader type="page" />}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
          <ApiLoader />
        </Suspense>
      </Provider>
    </AppContainer>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
