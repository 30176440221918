import { lazy } from 'react';
import paths from '@/configs/paths';

/*
 # Route template

 {
   component: lazy(() => import('@/components/pages/404')),
   path: '*',
   exact: true,
 }
*/

export const routes = [
  {
    path: paths.main,
    component: lazy(() => import('pages/Main')),
    exact: true,
  },
  {
    path: paths.federation,
    component: lazy(() => import('pages/Federation')),
  },
  {
    path: paths.news,
    component: lazy(() => import('pages/News')),
    exact: true,
  },
  {
    path: paths.newsDetailed,
    component: lazy(() => import('pages/NewsDetailed')),
  },
  {
    path: paths.calendar,
    component: lazy(() => import('pages/Calendar')),
  },
  {
    path: paths.eventDetailed,
    component: lazy(() => import('pages/EventDetailed')),
  },
  {
    path: paths.media,
    component: lazy(() => import('pages/Media')),
    exact: true,
  },
  {
    path: paths.mediaDetailed,
    component: lazy(() => import('pages/MediaDetailed')),
  },
  {
    path: paths.persons,
    component: lazy(() => import('pages/Persons')),
  },
  {
    path: paths.coachDetailed,
    component: lazy(() => import('pages/PersonDetailed')),
  },
  {
    path: paths.athleteDetailed,
    component: lazy(() => import('pages/PersonDetailed')),
  },
  {
    path: paths.documents,
    component: lazy(() => import('pages/Documents')),
  },
  {
    path: paths.search,
    component: lazy(() => import('pages/Search')),
  },
  {
    path: paths.notFound,
    component: lazy(() => import('pages/NotFound')),
  },
];

export const federationRoutes = [
  {
    path: paths.about,
    component: lazy(() => import('pages/About')),
  },
  {
    path: paths.council,
    component: lazy(() => import('pages/Council')),
  },
  {
    path: paths.organizations,
    component: lazy(() => import('pages/Organizations')),
  },
  {
    path: paths.history,
    component: lazy(() => import('pages/History')),
  },
  {
    path: paths.contacts,
    component: lazy(() => import('pages/Contacts')),
  },
];
