import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { routes } from '@/configs/routes';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import {http} from "@/api/apiConfig";

const Router = () => {
  const history = useHistory();

  http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 404) {
        history.replace('/*');
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <Header />
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={Math.random()} path={path} component={component} exact={exact} />
        ))}
      </Switch>
      <Footer />
    </>
  );
};

export default Router;
