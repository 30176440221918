const INITIAL_STATE = { list: [], current: {} };

export default function mediaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_MEDIA': {
      return { ...state, list: [...action.payload] };
    }
    case 'SET_MEDIA_CURRENT': {
      return { ...state, current: action.payload };
    }
    case 'CLEAR_MEDIA': {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
