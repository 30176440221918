const INITIAL_STATE = { list: [], current: {}, latest: [], pages: null };

export default function newsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_NEWS': {
      return { ...state, list: [...action.payload] };
    }
    case 'SET_NEWS_CURRENT': {
      return { ...state, current: action.payload };
    }
    case 'SET_NEWS_LATEST': {
      return { ...state, latest: [...action.payload] };
    }
    case 'SET_NEWS_PAGES': {
      return { ...state, pages: action.payload };
    }
    case 'CLEAR_NEWS': {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
