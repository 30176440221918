import axios from 'axios';

export const server = process.env.API_URL;

export const http = axios.create({
  baseURL: `${server}/api`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});
