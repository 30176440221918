/*
 # Path template
    path: '/path'
*/

const paths = {
  main: '/',
  federation: '/federation',
  about: '/federation/about',
  council: '/federation/council',
  organizations: '/federation/organizations',
  history: '/federation/history',
  contacts: '/federation/contacts',
  news: '/news',
  newsDetailed: '/news/:id',
  calendar: '/calendar',
  eventDetailed: '/events/:id',
  persons: '/persons/:type?',
  personsSA: '/persons/sa',
  personsFA: '/persons/fa',
  personsCoaches: '/persons/coaches',
  coachDetailed: '/person/coach/:id',
  athleteDetailed: '/person/athlete/:id',
  media: '/media',
  mediaDetailed: '/media/:id',
  documents: '/documents',
  search: '/search',
  notFound: '*',
};

export default paths;
