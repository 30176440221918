import { combineReducers } from 'redux';
import organizationsReducer from '@/reducers/organizationsReducer';
import searchReducer from '@/reducers/searchReducer';
import eventsReducer from '@/reducers/eventsReducer';
import documentsReducer from '@/reducers/documentsReducer';
import mediaReducer from '@/reducers/mediaReducer';
import personsReducer from '@/reducers/personsReducer';
import loaderReducer from '@/reducers/loaderReducer';
import councilReducer from '@/reducers/councilReducer';
import newsReducer from '@/reducers/newsReducer';

const reducers = combineReducers({
  loader: loaderReducer,
  organizations: organizationsReducer,
  search: searchReducer,
  events: eventsReducer,
  documents: documentsReducer,
  media: mediaReducer,
  persons: personsReducer,
  council: councilReducer,
  news: newsReducer,
});

export default reducers;
