const INITIAL_STATE = { persons: [], files: [], events: [], news: [] };

export default function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_SEARCH': {
      const { persons, files, events, news } = action.payload;
      return { persons, files, events, news };
    }
    case 'CLEAR_SEARCH': {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
