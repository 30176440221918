import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import paths from '@/configs/paths';
import CSS from '@/utils/css';
import VKIcon from 'images/vk.svg';
import './Footer.scss';

const today = new Date();

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer__map">
        <div className="footer__map_block">
          <Link to={paths.federation} className={CSS.merge('footer__link', ['primary'])}>
            {t('footer.federation')}
          </Link>
          <Link to={paths.about} className={CSS.merge('footer__link', ['secondary'])}>
            {t('footer.about')}
          </Link>
          <Link to={paths.council} className={CSS.merge('footer__link', ['secondary'])}>
            {t('footer.council')}
          </Link>
          <Link to={paths.organizations} className={CSS.merge('footer__link', ['secondary'])}>
            {t('footer.organizations')}
          </Link>
          <Link to={paths.history} className={CSS.merge('footer__link', ['secondary'])}>
            {t('footer.history')}
          </Link>
          <Link to={paths.contacts} className={CSS.merge('footer__link', ['secondary'])}>
            {t('footer.contacts')}
          </Link>
        </div>
        <div className="footer__map_block">
          <Link to={paths.persons} className={CSS.merge('footer__link', ['primary'])}>
            {t('footer.persons')}
          </Link>
          <Link to={paths.personsSA} className={CSS.merge('footer__link', ['secondary'])}>
            {t('footer.sport_aerobics_team')}
          </Link>
          <Link to={paths.personsFA} className={CSS.merge('footer__link', ['secondary'])}>
            {t('footer.fitness_aerobics_team')}
          </Link>
          <Link to={paths.personsCoaches} className={CSS.merge('footer__link', ['secondary'])}>
            {t('footer.coaches_and_judges')}
          </Link>
        </div>
        <div className="footer__map_block">
          <Link to={paths.calendar} className={CSS.merge('footer__link', ['primary'])}>
            {t('footer.calendar')}
          </Link>
          <Link to={paths.news} className={CSS.merge('footer__link', ['primary'])}>
            {t('footer.news')}
          </Link>
          <Link to={paths.media} className={CSS.merge('footer__link', ['primary'])}>
            {t('footer.media')}
          </Link>
          <Link to={paths.search} className={CSS.merge('footer__link', ['primary'])}>
            {t('footer.search')}
          </Link>
          <Link to={paths.documents} className={CSS.merge('footer__link', ['primary'])}>
            {t('footer.documents')}
          </Link>
        </div>
      </div>
      <div className="footer__social">
        <span className="footer__social_creds">{t('footer.company_credentials', { year: today.getFullYear() })}</span>
        <div className="footer__social_icons">
          <a
            href="https://vk.com/aerobicatomsk"
            target="_blank"
            rel="noreferrer"
            className="footer__social_icons__link"
          >
            <img src={VKIcon} alt={t('footer.vk')} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
