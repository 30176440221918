const INITIAL_STATE = [];

export default function councilReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_COUNCIL': {
      return [...action.payload].filter((item) => item).sort((a, b) => a?.last_name.localeCompare(b?.last_name));
    }
    case 'CLEAR_COUNCIL': {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
