const INITIAL_STATE = { list: [] };

export default function organizationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_ORGANIZATIONS_LIST': {
      return {
        ...state,
        list: [...action.payload].filter((item) => item).sort((a, b) => a?.title.localeCompare(b?.title)),
      };
    }
    default:
      return state;
  }
}
