const INITIAL_STATE = []

export default function documentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_DOCUMENTS': {
      return [...action.payload];
    }
    case 'CLEAR_DOCUMENTS': {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
