const INITIAL_STATE = { list: [], current: {} };

export default function personsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_PERSONS': {
      return {
        ...state,
        list: [...action.payload]
          .filter((item) => item)
          .sort((a, b) => a?.person?.last_name.localeCompare(b?.person?.last_name)),
      };
    }
    case 'SET_PERSONS_CURRENT': {
      return { ...state, current: action.payload };
    }
    case 'SET_PERSONS_RESULTS': {
      return { ...state, results: action.payload };
    }
    case 'CLEAR_PERSONS': {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
